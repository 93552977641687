import { useAuthStore } from '~/features/authentication/store/authorization';
import {
  EBattlepassLevelState,
  type IBattlepassIcons,
  type IBattlepassLevel,
  type IBattlepassTask,
} from '~/features/mainPage/Types/battlepass/client.types';
import { transform } from '~/features/mainPage/Types/battlepass/adapter';
import type { TBackgroundColor } from '~/types/Shared.types';

/* Хранилище батлпасса */
export const useBattlepassStore = defineStore('mainPage/battlepass', () => {
  const {
    $api: { mainPage: MainPageService },
  } = useNuxtApp();

  const authStore = useAuthStore();

  /* Const */
  /* Текущие коины в батлпассе */
  const currentCoins = ref(0);
  const icons = ref<IBattlepassIcons>({
    case: '',
    coin: '',
    rare: '',
    ultra: '',
  });
  /* Уровни в батлпассе */
  const levels = ref<IBattlepassLevel[]>([]);
  const progressColor = ref<TBackgroundColor>('');
  /* Доп.задания в батлпассе */
  const tasks = ref<IBattlepassTask[]>([]);
  const pendingLevels = ref<Set<number>>(new Set());
  const isLoaded = ref(false);

  /* Methods */
  /* Методы получения данных о батлпассе */
  const fetch = async () => {
    try {
      const data = await MainPageService.getBattlepass();
      const transformedData = transform(data);
      if (!authStore.isAuth) {
        currentCoins.value = 0;
      } else {
        currentCoins.value = transformedData.currentCoins;
      }

      icons.value = transformedData.icons;
      levels.value = transformedData.levels;
      progressColor.value = transformedData.progress;
      tasks.value = transformedData.tasks;
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };
  /* Метод получения приза из батлпаса */
  const claimPrize = async (prizeId: number) => {
    if (!prizeId) return false;
    if (pendingLevels.value.has(prizeId)) return;

    pendingLevels.value.add(prizeId);
    try {
      await MainPageService.claimPrize(prizeId);
      const claimedLevel = levels.value.find((level) => level.level === prizeId);
      if (claimedLevel) {
        claimedLevel.state = EBattlepassLevelState.RECEIVED;
      }
      return true;
    } catch {
      return false;
    } finally {
      pendingLevels.value.delete(prizeId);
    }
  };

  return {
    claimPrize,
    currentCoins,
    fetch,
    icons,
    isLoaded,
    levels,
    pendingLevels,
    progressColor,
    tasks,
  };
});
